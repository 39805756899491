export const AUTH_API = {
  USER: "/rest-auth/user/",
  LOGIN: "/rest-auth/login/",
  REGISTER: "/rest-auth/registration/",
  CHANGE_PASSWORD: "/rest-auth/password/change/",
  RESET_PASSWORD: "/rest-auth/password/reset/",
  RESET_PASSWORD_CONFIRM: "/rest-auth/password/reset/confirm/",
  VERIFY_REGISTER_EMAIL: "/rest-auth/registration/verify-email/",
  USER_MANAGEMENT: "/api/user-management/",
  VERIFY_REFERRAL_CODE: "/api/verify-referral-code/",
};

export const PHARMACY_API = {
  SYSTEM_TYPE: "api/system-type/",
  PHARMACY: "api/pharmacy/",
  PHARMACY_USER: "api/pharmacy-user/",
  GET_PHARMACY: "api/get-pharmacy/",
  PHARMACY_SETTINGS: "api/pharmacy-settings/",
  REGISTER: "api/pharmacy/",
  TEST_CONNECTION: "api/test-db-connection/",
  PHARMACY_PRODUCTS: "api/pricerevision/purchased-items/",
  PHARMACY_PRODUCT_CLIENTS: "api/pricerevision/items-last-sales/",
  // PHARMACY_PRODUCT_CLIENTS: 'api/pricerevision/price-revision/clients/',
  PHARMACY_PRODUCT_BUYER_CLIENTS: "api/pricerevision/items-last-purchases/",
  // PHARMACY_PRODUCT_BUYER_CLIENTS: 'api/pharmacy/price-revision/buyer-clients/',
  PHARMACY_ISTOCK: "api/pricerevision/pharmacy-product-stock/",
  // PHARMACY_ISTOCK: 'api/pharmacy/price-revision/total-istock/',
  PRICE_UPDATE: "api/pricerevision/Update_Product_Price/",
  // PRICE_UPDATE: 'api/pharmacy/price-revision/update-price/',
  PRICE_UPDATE_HISTORY: "api/pricerevision/price-update-history/",
  // PRICE_UPDATE_HISTORY: 'api/pharmacy/price-revision/price-update-history/',
};

export const VITALFAR_API = {
  INTERNET_PRICES: "S03_crw/internet-prices/",
  PRODUCT_MARKETPLACES: "S03_crw/product-marketplaces",
  SELLERS_LIST: "S03_crw/sellers-list/",
};

export const BUY_API = {
  PH_NEEDS: "api/buy/pharmacy_needs/",
  BUY_GROUP: "api/buy/group/",
  BUY_GET_PHARMACIES: "api/buy/pharmacy/list/",
  GROUP_PHARMACIES: "api/buy/group/pharmacy/",
  LEAVE_GROUP: "api/buy/group/pharmacy/leave_pharmacy/",
  PHARMACIES_LIST: "api/buy/group/pharmacies/",
  LABS: "api/buy/lab/list/",
  BUY: "api/buy/list/",
  BUY_PARTICIPATED_PHARMACIES: "api/buy/list/participated-pharmacies/",
  BUY_CATEGORY: "api/buy/category/",
  BUY_CATEGORIES: "api/buy/categories/",
  BUY_CONDITION: "api/buy/condition/",
  BUY_PRODUCT_CONDITION: "api/buy/condition/add-product-condition/",
  Add_BUY_PRODUCT: "api/buy/product/",
  Update_BUY_PRODUCT: "api/buy/product/",
  BUY_PRODUCT: "api/buy/get/",
  BUY_PRODUCT_DETAILS: "api/buy/order/get-buy-products/",
  BUY_NEED: "api/buy/need/",
  BUY_PARA_NEED: "api/buy/para-need/",
  BUY_UPLOAD_PARA_NEED: "api/buy/para-need/create-para-pharamcy-needs/",
  V1_BUY_NEED: "api/v1/buy/need/",
  BUY_NEED_BULK_UPDATE: "api/buy/need/bulk-update/",
  BUY_IMPORT_CATALOG: "api/buy/product/import-catalog/",
  BUY_PARTICIPATE: "/api/buy/participate/",
  BUY_ORDER: "/api/buy/order/",
  BUY_DETAILS: "/api/buy/need/entered-needs/",
  GET_BUY_ORDER: "/api/buy/order/get-orders/",
  BUY_ORDER_CALCULATED: "/api/buy/calculated/order/",
  BUY_ORDER_ITEM: "/api/buy/order/item/",
  BUY_ORDER_ITEM_NEEDS: "/api/buy/order/item/needs/",
  SHIPMENT: "/api/shipment/",
  SHIPMENT_ITEM: "/api/shipment/items/",
  SHIPMENT_ITEM_PHARMACIES: "/api/shipment/item/pharmacies/",
  SHIPMENT_PHARMACY_ITEMS: "/api/shipment/pharmacy/items/",
  SHIPMENT_DISTRIBUTION_ITEMS:
    "/api/shipment/item/pharmacies/create-distribution-item",
  LOAD_STATS: "api/v1/set/load_stat/",
  SAVE_STATS: "api/v1/set/save_stat/",
  ADJUST_NEED: "api/v1/adjust_need/",
  ORDER_REPORT: "api/buy/order_gen/",
  PHARMACY_REPORT: "api/buy/ship_report/",
  SHIPMENT_REPORT: "api/buy/ship_report/",
  ORDER_DOC: "api/buy/generate_order_report/",
  SHIPMENT_INFO: "api/shipment/info/",

  BUY_ORDER_CONDITION: "api/buy/condition/",
  BUY_GET_CONDITION: "api/buy/condition/get-condition/",

  CREATE_ORDER_ITEM: "/api/buy/order/item/create-order-items/",
  CREATE_SHIPMENT_ITEM: "/api/shipment/items/create-shipment-items/",
  UPDATE_SHIPMENT_ITEM: "/api/shipment/items/update-shipment-items/",
  BUY_ACTION: "api/buy/buy_action/",
  ACTION: "api/buy/action/",
  GET_CURRENT_PHARMACIES: "api/pharmacy/get-related-pharamcy/",
  PURCHASE_FOR_OTHER_PHARMACY: "api/buy/purchase_order_for_other_pharmacy/",
  PURCHASE_FOR_BY_OTHER_PHARMACY_FOR_MY_PHARMACY:
    "api/buy/purchase_order_by_other_pharmacy_for_my_pharmacy/",
  SHIPMENT_PURCHASE_FOR_OTHER_PHARMACY:
    "api/buy/purchase_shipment_for_other_pharmacy/",
  SHIPMENT_PURCHASE_FOR_BY_OTHER_PHARMACY_FOR_MY_PHARMACY:
    "api/buy/purchase_shipment_by_other_pharmacy_for_my_pharmacy/",
  PHARMACY_BUY_REPORT: "api/buy/report_data/",
  PHARMACY_LABS: "api/ph_labs/",
  PHARMACY_LAB_SYNC: "api/ph_labs/sync-labs/",
};

export const REPORT_API = {
  SHIPMENTS: "/api/report/pharmacy/shipments/",
  REPORT_ITEM: "/api/buy/report/",
};
