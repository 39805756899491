import { createSlice } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { dispatch } from '../store';

// Utils
import axios from '../../utils/axios';

// Paths
import { AUTH_API, BUY_API } from '../../constants/ApiPaths';

// Token Varialbles
let cancelUserList = null;

const initialState = {
  // States for User Management
  loadingList: false,
  loadingLabList: false,
  userList: [],
  labList: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Users
    startUserLoading(state) {
      state.loadingList = true;
    },
    startLabLoading(state) {
      state.loadingLabList = true;
    },
    getUserSuccess(state, action) {
      state.loadingList = false;
      state.userList = action.payload;
    },
    getLabSuccess(state, action) {
      state.loadingLabList = false;
      state.labList = action.payload;
    },
    updateLabSuccess(state, action){
      const labItems = state.labList.map((row) => {
        if (row.id === action.payload.id) {
          return action.payload;
        }
        return row;
      });
      state.labList = labItems;
    },
    addUser(state, action) {
      state.userList = [...state.userList, action.payload];
    },
  },
});

// Reducers
export default slice.reducer;

// Actions
export const { addUser, getUserSuccess, getLabSuccess, updateLabSuccess } = slice.actions;

// ------------------------------------------------

export function getUserList(search) {
  // Get the list of shipments for an order and store in the redux store
  return async () => {
    dispatch(slice.actions.startUserLoading());

    // Cancel the previous running request
    if (cancelUserList) cancelUserList();

    // Get User List
    axios
      .get(AUTH_API.USER_MANAGEMENT, {
        params: search,
        cancelToken: new CancelToken((c) => {
          // An executor function receives a cancel function as a parameter
          cancelUserList = c;
        }),
      })
      .then((response) => {
        // console.log('User List: ', response);
        dispatch(slice.actions.getUserSuccess(response.data));
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}


export function getLabList(pharmacy_id) {
  return async () => {
    axios
      .get(BUY_API.PHARMACY_LAB_SYNC, {
        params: { pharmacy_id: pharmacy_id },
        cancelToken: new CancelToken((c) => {
          cancelUserList = c;
        }),
      })
      .then((response) => {
        dispatch(slice.actions.startLabLoading());
        if (cancelUserList) cancelUserList();
        axios
          .get(BUY_API.PHARMACY_LABS, {
            params: { pharmacy_id: pharmacy_id },
            cancelToken: new CancelToken((c) => {
              cancelUserList = c;
            }),
          })
          .then((response) => {
            console.log({ response })
            dispatch(slice.actions.getLabSuccess(response.data));
          })
          .catch((error) => {
            // console.log(error);
          });
      })

  };
}