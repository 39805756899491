import { useEffect, useState, useCallback } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, MenuItem, Select, Stack, Typography, Button } from "@mui/material";
import styled from "styled-components";
import { sentenceCase } from "change-case";
import useLocales from "../../../hooks/useLocales";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../utils/axios";
import { BUY_API } from "../../../constants/ApiPaths";
import HeaderBreadcrumbs from "../../../components/Breadcrumbs";
import Iconify from "../../../components/Iconify";
import DialogModal from "../../../components/DialogModal";
import { PATH_BUY } from "../../../routes/paths";
import {
  getPharmacyItems,
  getShipmentItems,
  getShipmentList,
} from "../../../redux/slices/shipment";
import { getBuyCategories, getBuyConditions } from "../../../redux/slices/buy";
import ShipmentProductFormAdd from "../../../sections/buy/components/ShipmentProductFormAdd";

// Styled Components
const StyledSelect = styled(Select)`
  fieldset {
    border: none;
  }
`;

export default function BuyOrderShipmentHeader({ currentBuy }) {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { user, currentPharmacy } = useAuth();
  const { shipmentList } = useSelector((state) => state.shipment);
  const { id = "" } = useParams();
  const navigate = useNavigate();

  const [shipment, setShipment] = useState(null);
  const [products, setProducts] = useState([]);
  const [loadingShipment, setLoadingShipment] = useState(true);
  const [isAddProductOpen, setIsAddProductOpen] = useState(false);

  // Fetch product details for a specific buy
  const fetchBuyProductDetails = async (buyId) => {
    try {
      const response = await axios.get(BUY_API.BUY_PRODUCT_DETAILS, {
        params: { buy: buyId },
      });
      setProducts(response.data);
    } catch (error) {
      console.error("Failed to fetch product details:", error);
    }
  };

  // Fetch shipment data
  const fetchShipmentData = useCallback(async () => {
    try {
      const response = await axios.get(`${BUY_API.SHIPMENT}${id}/`);
      const shipmentData = response.data;
      setShipment(shipmentData);
      setLoadingShipment(false);

      await fetchBuyProductDetails(shipmentData.buy_id);

      // Dispatch Redux actions for categories and conditions
      dispatch(getBuyCategories(shipmentData.buy_id));
      dispatch(getBuyConditions(shipmentData.buy_id));
    } catch (error) {
      console.error("Failed to fetch shipment data:", error);
      setLoadingShipment(false);
      navigate(PATH_BUY.buyList, { replace: true });
    }
  }, [dispatch, id, navigate]);

  useEffect(() => {
    const loadShipmentData = async () => {
      if (!id) {
        navigate(PATH_BUY.buyList, { replace: true });
        return;
      }

      setLoadingShipment(true);

      if (shipmentList?.length) {
        const foundShipment = shipmentList.find(
          (shipment) => shipment.id === parseInt(id, 10)
        );

        if (!foundShipment) {
          navigate(PATH_BUY.buyList, { replace: true });
          return;
        }

        setShipment(foundShipment);
        setLoadingShipment(false);
        await fetchBuyProductDetails(foundShipment.order.buy);
      } else {
        await fetchShipmentData();
      }

      // Load the shipment and pharmacy items
      dispatch(getShipmentItems({ shipment: id }));
      dispatch(
        getPharmacyItems({
          shipment_item__shipment: id,
          pharmacy: currentPharmacy.id,
        })
      );
    };

    loadShipmentData();
  }, [id, shipmentList, fetchShipmentData, dispatch, navigate, currentPharmacy]);

  // Handlers for Add Product Modal
  const handleOpenAddProduct = () => setIsAddProductOpen(true);
  const handleCloseAddProduct = () => setIsAddProductOpen(false);

  const orderId = shipment?.order?.id || shipment?.order || "";
  const buyId = shipment?.buy_id || "";
  const buyName = shipment?.buy_name || "";
  const orderName = shipment?.order_name || "";

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ mb: 5, justifyContent: "space-between", width: "100%", mt: 5 }}
      >
        <Box>
          <Typography variant="h4" color="text.primary">
            {translate("shipment.shipment_list_heading")}
          </Typography>
          <HeaderBreadcrumbs
            heading={translate("shipment.shipment_detail_heading")}
            links={[
              { name: translate("shipment.buy_list_heading"), href: PATH_BUY.buyList },
              { name: sentenceCase(buyName || ""), href: PATH_BUY.buyList },
              { name: translate("shipment.order_list_heading"), href: PATH_BUY.buyOrders.replace(":buyId", buyId) },
              { name: sentenceCase(orderName || ""), href: PATH_BUY.buyList },
              { name: translate("shipment.shipment_list_heading"), href: PATH_BUY.buyShipments.replace(":orderId", orderId) },
              { name: `Shipment: ${shipment?.shipment_number || ""}` },
            ]}
            action={<></>}
          />
        </Box>
        <Button
          variant="contained"
          onClick={handleOpenAddProduct}
          startIcon={<Iconify icon="eva:plus-fill" />}
          sx={{ mr: 2 }}
        >
          {translate("shipment.add_product")}
        </Button>
      </Stack>
      <DialogModal
        open={isAddProductOpen}
        onClose={handleCloseAddProduct}
        DialogContentItems={
          <ShipmentProductFormAdd
            currentBuy={currentBuy}
            shipmentItems={shipment}
            products={products}
            onClose={handleCloseAddProduct}
          />
        }
      />
    </>
  );
}
