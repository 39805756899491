import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';

// Redux
import { useDispatch } from '../../../../redux/store';
import { addShipment, getShipmentList } from '../../../../redux/slices/shipment';

// Hooks
import useLocales from '../../../../hooks/useLocales';
import useAuth from '../../../../hooks/useAuth';

// Components
import { FormProvider, RHFTextField, RHFDesktopDatePicker } from '../../../../components/hook-form';

// Utils
import axios from '../../../../utils/axios';
import { fDateForDB } from '../../../../utils/formatTime';

// Paths
import { BUY_API } from '../../../../constants/ApiPaths';
import { InputAdornment } from '@mui/material';
import { getParticipatedBuysSuccess } from '../../../../redux/slices/buy';

AgreementBuyForm.propTypes = {
  buy: PropTypes.object,
  openDialog: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
};

function AgreementBuyForm({ buy, openDialog, handleCloseDialog }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const { user } = useAuth();

  const ShipmentSchema = Yup.object().shape({
    buy_name: Yup.string().required('Buy Name is required'),
    estimate_period: Yup.string().required('Estimate Period is required'),
  });

  const defaultValues = {
    buy_name: '',
    estimate_period: '',
  };

  const methods = useForm({
    resolver: yupResolver(ShipmentSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const onSubmit = async (data) => {
    const groupIds = buy.buygroup_set.map(group => group.group_id);

    const formData = {
      name: data.buy_name,
      type: "INDIVIDUAL",
      from_date: buy?.from_date,
      to_date: buy?.to_date,
      catalog_source: buy.catalog_source,
      lab_id: buy.lab_id,
      lab_name: buy.lab_name,
      previous_buy: buy.previous_buy,
      payment_method: buy.payment_method,
      period_estimation: data.estimate_period,
      contact_person: buy.contact_person,
      contact_phone: buy.contact_phone,
      min_purchase: buy.min_purchase,
      max_person: buy.max_person,
      cost: buy.cost,
      commission: buy.commission,
      distance: buy.distance,
      pharmacy: buy.pharmacy,
      invited_pharmacy: buy.invited_pharmacy,
      parent_buy: buy.id,
      groups: groupIds.join(','),
    };

    console.log({buy, formData, data})
    return axios({
      method: 'post',
      url: `${BUY_API.BUY}`,
      data: formData,
    })
      .then((response) => {
        console.log(response);
        window.location.reload();
      })
      .catch((error) => {
        // console.log(error);
        enqueueSnackbar('Oops something went wrong.', { variant: 'error' });
      });
  };


  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{translate('buy.new_buy')}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item md={12}>
              <RHFTextField name="buy_name" label={translate('buy.buy_name')} autoFocus />
            </Grid>
            <Grid item md={12}>
              <RHFTextField
                  type="number"
                  name="estimate_period"
                  label={translate('buy.estimation_period')}
                  id="estimate_period"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{translate('buy.months')}</InputAdornment>,
                  }}
                />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" variant="contained" onClick={handleCloseDialog}>
            {translate('cancel')}
          </Button>
          <LoadingButton type="submit" color="primary" variant="contained" loading={isSubmitting}>
            {translate('save')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

export default AgreementBuyForm;
